import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareCaseStudies, {
  EasySoftwareCaseStudiesType,
} from '../EasySoftwareCaseStudies/EasySoftwareCaseStudies'

export interface WidgetEasySoftwareCaseStudiesType
  extends WidgetBase,
  EasySoftwareCaseStudiesType {
  contentType: 'EasySoftwareCaseStudies'
}

const WidgetEasySoftwareCaseStudies: FunctionComponent<WidgetEasySoftwareCaseStudiesType> = (
  props
) => (
  <WidgetContainer
    thin
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareCaseStudies
      displayText={props.displayText}
      caseStudiesList={props.caseStudiesList}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareCaseStudies
